<template>
  <div class="s_system_config_base g-flex-column" v-show="show">
    <el-form ref="form" label-width="200px" label-position="right">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="本金最低提现金额（元）: ">
            <el-input v-model="form.bj"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="佣金最低提现金额: ">
            <el-input v-model="form.yj"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="100元以下提现手续费: ">
            <el-input v-model="form.yj_fee"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="开启提现申请: ">
              <el-radio-group v-model="form.switch">
              <el-radio :label="'1'">开</el-radio>
              <el-radio :label="'2'">关</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item label="周几可以提现: ">
            <div class="g-flex-align-center">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <el-checkbox-group style="margin: 0 15px;" class="g-flex-align-center" v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="city in cities" :label="city.value" :key="city.value">{{city.title}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" size="large" @click="submitClick">确定修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { apiGetSystemConfig, apiSetSystemConfig } from '@/utils/api.js'
  export default {
    data() {
      return {
        code: '',
        show: false,
        checkAll: false,
        checkedCities: [], //选中的值
        cities:  [{ title: '周一', value: '1' },{ title: '周二', value: '2' },{ title: '周三', value: '3' },{ title: '周四', value: '4' },{ title: '周五', value: '5' },{ title: '周六', value: '6' }, { title: '周日', value: '0' }],
        isIndeterminate: true,
        form: {
          date: '',
          switch: '1',
          bj: '',
          yj: '',
          yj_fee: ''
        }
      }
    },
    methods: {
      // 全选点击
      handleCheckAllChange(val) {
        this.checkedCities = val ? this.cities.map(item => { return item.value }) : [];
        this.isIndeterminate = false;
      },

      // 每一个点击选中监听
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },

      onShow(code) {
        this.code = code
        this.show = true
        this.apiGetSystemConfigHandel()
      },

      // 获取配置
      async apiGetSystemConfigHandel() {
        let loading = this.$loading()
        const { success, data } = await apiGetSystemConfig({ code: this.code}).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.checkedCities = data.data.date.split(',')
        this.form = data.data
        this.handleCheckedCitiesChange(this.checkedCities)
      },

      // 修改配置
      async submitClick() {
        let loading = this.$loading()
        this.form.date = this.checkedCities.join(',')
        const { success, data } = await apiSetSystemConfig({ code: this.code, config: this.form }).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
      },
    }

  }
</script>

<style lang="scss">
.s_system_config_base {
  .s-system-config-base-btn {
    padding-top: 30px;
  }
}
</style>