<template>
 <div class="c_element_upload_img">
    <el-upload :id="idNum" action="" :http-request="uploadFile" :show-file-list="false" :before-upload="beforeUpload" :accept="uploadAccept"></el-upload>
 </div>
</template>

<script>
import { apiUploadFile } from '@/utils/api.js'
export default {
  props: {
    idNum: {
      type: String,
      default() {
        return 'upload-button-img'
      }
    }
    
  },
  data() {
    return {
      uploadAccept: ".jpeg,.jpg,.png,.JPEG,.JPG,.PNG",
    }
  },
  methods: {
    // 上传
    uploadClick() {
      document.getElementById(this.idNum).getElementsByClassName('el-upload__input')[0].click()
    },
    // 上传图片
    async uploadFile(param) {
      let formData = new FormData()
      formData.append('file', param.file)
      const loading = this.$loading()
      const { success, data } = await apiUploadFile(formData)
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitUploadSuccess', data.url)
    },

    // 上传前 return false 即停止上传
    beforeUpload() {
      return true
    },
  }
}
</script>

<style lang='scss'>

</style>