<template>
  <div class="s_system_config_base g-flex-column" v-show="show">
    <el-form ref="form" label-width="100px" label-position="right">
      <el-row>
        <el-col :span="20">
          <el-form-item label="买家公告标题: ">
            <el-input v-model="form.bullent_title"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
         <el-col :span="24">
           <!-- 富文本 -->
          <TinymceRich v-model="form.bullent" @handleImgUpload="apiUploadFileHandel"/>
         </el-col>
      </el-row>

      <el-form-item class="g-martop30">
        <el-button type="primary" size="large" @click="submitClick">确定修改</el-button>
      </el-form-item>
    </el-form>


    
  </div>
</template>

<script>
import { apiGetSystemConfig, apiSetSystemConfig, apiUploadFile } from '@/utils/api.js'
import TinymceRich from '@/components/TinymceRich.vue'

  export default {
    components: { TinymceRich }, 
    data() {
      return {
        code: '',
        show: false,
        form: {
          bullent: '',
          bullent_title: ''
        }
      }
    },
    methods: {
      //鼠标单击的事件
      onClick(e, editor) {
        console.log('Element clicked')
        console.log(e)
        console.log(editor)
      },
      //清空内容
      clear() {
        this.$refs.editor.clear()
      },
      onShow(code) {
        this.code = code
        this.show = true
        this.apiGetSystemConfigHandel()
      },

     // 上传
      async apiUploadFileHandel(blobInfo, resolve, failure) {
        let file = blobInfo.blob()
        console.log(file)
        let formData = new FormData()
        formData.append('file', file)
        const loading = this.$loading()
        const { success, data } = await apiUploadFile(formData).catch(() => failure())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        resolve(data.url)
        console.log(data)
      },

      async apiGetSystemConfigHandel() {
        let loading = this.$loading()
        const { success, data } = await apiGetSystemConfig({ code: this.code}).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.form = data.data
        console.log(data)
      },

      async submitClick() {
        console.log(this.msg)
        let loading = this.$loading()
        const { success, data } = await apiSetSystemConfig({ code: this.code, config: this.form }).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
      },
    }

  }
</script>

<style lang="scss">
.s_system_config_base {
  .s-system-config-base-btn {
    padding-top: 30px;
  }
}
</style>