<template>
  <div class="s_system_config_base g-flex-column" v-show="show">
    <el-form ref="form" :model="form" label-width="200px" label-position="right">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="管理员后台域名前缀: ">
            <el-input v-model="form.super_prefix"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="商家后台域名前缀: ">
            <el-input v-model="form.shop_prefix"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="前台静态路径: ">
            <el-input v-model="form.home_path"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="后台静态路径: ">
            <el-input v-model="form.admin_path"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="前台域名前缀: ">
            <el-input v-model="form.home_prefix"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" size="large" @click="submitClick">确定修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { apiGetSystemConfig, apiSetSystemConfig } from '@/utils/api.js'
  export default {
    data() {
      return {
        code: '',
        show: false,
        form: {
          super_prefix: '',
          shop_prefix: '',
          home_path: '',
          admin_path: '',
          home_prefix: '',
        }
      }
    },
    methods: {
      onShow(code) {
        this.code = code
        this.show = true
        this.apiGetSystemConfigHandel()
      },

      async apiGetSystemConfigHandel() {
        let loading = this.$loading()
        const { success, data } = await apiGetSystemConfig({ code: this.code}).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.form = data.data
        console.log(data)
      },

      async submitClick() {
        let loading = this.$loading()
        const { success, data } = await apiSetSystemConfig({ code: this.code, config: this.form }).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
      },
    }

  }
</script>

<style lang="scss">
.s_system_config_base {
  .s-system-config-base-btn {
    padding-top: 30px;
  }
}
</style>