<template>
  <div class="s_system_config_base g-flex-column" v-show="show">
    <el-form ref="form" :model="form" label-width="200px" label-position="right">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item label="QQ客服：">
            <el-input v-model="form.qq"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <el-form-item label="微信客服：">
            <el-input v-model="form.wechat"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" size="large" @click="submitClick">确定修改</el-button>
      </el-form-item>
    </el-form>
    
    <element-upload-img idNum="SystemConfigAgentKefuUploadImg" ref="ElementUploadImg" @emitUploadSuccess="emitUploadSuccess"/>
    
  </div>
</template>

<script>
import { apiGetSystemConfig, apiSetSystemConfig, apiInit } from '@/utils/api.js'
import  ElementUploadImg from '@/components/ElementUploadImg.vue'
  export default {
    components: { ElementUploadImg },
    data() {
      return {
        code: '',
        show: false,
        imageUrl: '',
        form: {
          qq: "",
          mobile: "",
          wechat: "",
          wechat_img: "",
        }
      }
    },
    methods: {
      // 上传成功
      emitUploadSuccess(url) {
        console.log(url)
        this.form.wechat_img = url
      },

      // 初始化获取信息
      async apiInitHandel() {
        const { success, data } = await apiInit()
        if(!success) return
        console.log('apiInitHandel', data)
        this.$global.adminUser=data.user
        this.$global.config.bank = data.bank
        this.$global.config.system = data.system
        this.$global.config.kefu = data.kefu
        document.title = this.$global.config.system.title
      },

      // 点击上传
      uploadClick() {
        this.$refs.ElementUploadImg.uploadClick()
      },

      removeImgUrl() {
        this.form.wechat_img = ''
      },
      
      onShow(code) {
        this.code = code
        this.show = true
        this.apiGetSystemConfigHandel()
      },
 
      async apiGetSystemConfigHandel() {
        let loading = this.$loading()
        const { success, data } = await apiGetSystemConfig({ code: this.code}).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.form = data.data
        console.log(data)
      },

      async submitClick() {
        let loading = this.$loading()
        const { success, data } = await apiSetSystemConfig({ code: this.code, config: this.form }).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        this.apiInitHandel()
      },
    }

  }
</script>

<style lang="scss">
.s_system_config_base {
  .s-system-config-base-btn {
    padding-top: 30px;
  }
  .s-avatar-uploader {
    width: 200px;
    height: 200px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .iconcuo {
      position: absolute;
      background: rgba($color: #000000, $alpha: 0.5);
      font-size: 26px;
      color: $white;
      padding: 6px;
      line-height: 20px;
      border-radius: 0 0 0 10px;
      right: 0;
      top: 0;
      cursor: pointer;
    }
    .s-avatar-uploader-tips {
      height: 100%;
      width: 100%;
      .s-avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
      }  
      span {
        color: #909399;
      }
    }
    .s-avatar {
      width: 200px;
      height: 200px;
      object-fit: cover;
      vertical-align: middle;
    }
  }


}
</style>