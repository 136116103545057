<template>
  <el-card class="v_system_config">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="基础配置" name="SYSTEM" v-if="userType == 100"></el-tab-pane>
        <el-tab-pane label="域名配置" name="DOMAIN" v-if="userType == 100"></el-tab-pane>
        <el-tab-pane label="阿里云oss配置" name="ALIYUN" v-if="userType == 100"></el-tab-pane>
        <el-tab-pane label="短信配置" name="SMS" v-if="userType == 100"></el-tab-pane>
        <el-tab-pane label="短信接口设置" name="SMS_TPL"></el-tab-pane>
        <el-tab-pane label="收款配置" name="ZHCZ"></el-tab-pane>
        <el-tab-pane label="提现设置" name="CASH"></el-tab-pane>
        <el-tab-pane label="买家客服设置" name="BUY_KEFU"></el-tab-pane>
        <el-tab-pane label="商家客服设置" name="KEFU"></el-tab-pane>
        <el-tab-pane label="代理客服设置" name="AGENT_KEFU"></el-tab-pane>
        <el-tab-pane label="推广设置" name="SPREAD"></el-tab-pane>
        <el-tab-pane label="买家推广设置" name="SPREAD_BUY"></el-tab-pane>
        <el-tab-pane label="平台基础服务费设置" name="TASK"></el-tab-pane>
        <el-tab-pane label="买家公告设置" name="BULLETIN"></el-tab-pane>
        <el-tab-pane label="代理公告设置" name="AGENT_BULLETIN"></el-tab-pane>
        <el-tab-pane label="快递物流设置" name="EXPRESS"></el-tab-pane>
          <!-- <el-tab-pane label="会员设置" name="5"></el-tab-pane>
        <el-tab-pane label="商家设置" name="6"></el-tab-pane>
        <el-tab-pane label="代理设置" name="7"></el-tab-pane> -->
        

        <div class="v-system-config-show-box">
          <system-config-base ref="SystemConfigBase" v-show="activeName === 'SYSTEM' && userType == 100"/>

          <system-config-domain ref="SystemConfigDomain" v-show="activeName === 'DOMAIN' && userType == 100"/>

          <system-config-oss ref="SystemConfigOss" v-show="activeName === 'ALIYUN' && userType == 100"/>

          <!-- 短信配置 -->
          <system-config-sms  ref="SystemConfigSms" v-show="activeName === 'SMS' && userType == 100"/>

          <!-- 短信接口设置 -->
          <system-config-sms-tpl ref="SystemConfigSmsTpl" v-show="activeName === 'SMS_TPL'"/>
          
          <!-- 收款配置 -->
          <system-configzhcz ref="SystemConfigzhcz" v-show="activeName === 'ZHCZ'"/>

          <!-- 提现设置 -->
          <system-config-cash-out ref="SystemConfigCashOut" v-show="activeName==='CASH'"/>

          <!-- 买家客服设置 -->
          <system-config-buy-ke-fu ref="SystemConfigBuyKeFu" v-show="activeName==='BUY_KEFU'"/>

          <!-- 商家客服设置 -->
          <system-config-ke-fu ref="SystemConfigKeFu" v-show="activeName==='KEFU'"/>

          <!-- 代理客服设置 -->
          <system-config-agent-kefu ref="SystemConfigAgentKefu" v-show="activeName==='AGENT_KEFU'"/>
          
          <!-- 推广 -->
          <system-config-spread ref="SystemConfigSpread" v-show="activeName === 'SPREAD'"/>

          <!-- 买家推广佣金 -->
          <system-config-spread-buy  ref="SystemConfigSpreadBuy" v-show="activeName === 'SPREAD_BUY'"/>

          <!-- 平台基础服务费配置 -->
          <system-config-task ref="SystemConfigTask" v-show="activeName === 'TASK'"/>
          
          <!-- 公告设置 -->
          <system-config-bulletin ref="SystemConfigBulletin" v-show="activeName === 'BULLETIN'"/>

          <!-- 代理公告设置 -->
          <system-config-agent-bulletin ref="SystemConfigAgentBulletin" v-show="activeName === 'AGENT_BULLETIN'"/>

          <!-- 快递溢价 token -->
          <system-config-express ref="SystemConfigExpress" v-show="activeName === 'EXPRESS'"/>
        </div>
    </el-tabs>
  </el-card>
</template>

<script>
  import SystemConfigBase from './SystemConfigBase.vue'
  import SystemConfigDomain from './SystemConfigDomain.vue'
  import SystemConfigOss from './SystemConfigOss.vue'
  import SystemConfigSms from './SystemConfigSms.vue'
  import SystemConfigSpread from './SystemConfigSpread.vue'
  import SystemConfigSpreadBuy from './SystemConfigSpreadBuy.vue'
  import SystemConfigBulletin from './SystemConfigBulletin.vue'
  import SystemConfigzhcz from './SystemConfigzhcz.vue'
  import SystemConfigCashOut from './SystemConfigCashOut.vue'
  import SystemConfigKeFu from './SystemConfigKeFu.vue';
  import SystemConfigSmsTpl from './SystemConfigSmsTpl.vue'
  import SystemConfigBuyKeFu from './SystemConfigBuyKefuu.vue'
  import SystemConfigTask from './SystemConfigTask.vue'
  import SystemConfigAgentBulletin from './SystemConfigAgentBulletin.vue'
  import SystemConfigAgentKefu from './SystemConfigAgentKefu.vue'
  import SystemConfigExpress from './SystemConfigExpress'
  export default {
    components: { 
    SystemConfigBase, 
    SystemConfigDomain, 
    SystemConfigOss, 
    SystemConfigSms,
    SystemConfigSpread, 
    SystemConfigSpreadBuy, 
    SystemConfigBulletin, 
    SystemConfigzhcz, 
    SystemConfigCashOut,
    SystemConfigKeFu,
    SystemConfigSmsTpl,
    SystemConfigBuyKeFu,
    SystemConfigTask,
    SystemConfigAgentBulletin,
    SystemConfigAgentKefu,
    SystemConfigExpress },
    data() {
      return {
        activeName: 'SYSTEM'
      }
    },
    mounted() {
      this.$refs.SystemConfigBase.onShow(this.activeName)
    },
    methods: {
      handleClick() {
        switch (this.activeName) {
          case 'SYSTEM': this.$refs.SystemConfigBase.onShow(this.activeName); break;
          case 'DOMAIN': this.$refs.SystemConfigDomain.onShow(this.activeName); break;
          case 'ALIYUN': this.$refs.SystemConfigOss.onShow(this.activeName); break;
          case 'SMS': this.$refs.SystemConfigSms.onShow(this.activeName); break;
          case 'SPREAD': this.$refs.SystemConfigSpread.onShow(this.activeName); break;
          case 'SPREAD_BUY': this.$refs.SystemConfigSpreadBuy.onShow(this.activeName); break;
          case 'BULLETIN': this.$refs.SystemConfigBulletin.onShow(this.activeName); break;
          case 'ZHCZ': this.$refs.SystemConfigzhcz.onShow(this.activeName); break;
          case 'CASH': this.$refs.SystemConfigCashOut.onShow(this.activeName); break;
          case 'BUY_KEFU': this.$refs.SystemConfigBuyKeFu.onShow(this.activeName); break;
          case 'KEFU': this.$refs.SystemConfigKeFu.onShow(this.activeName); break;
          case 'TASK': this.$refs.SystemConfigTask.onShow(this.activeName); break;
          case 'SMS_TPL': this.$refs.SystemConfigSmsTpl.onShow(this.activeName); break;
          case 'AGENT_BULLETIN': this.$refs.SystemConfigAgentBulletin.onShow(this.activeName); break;
          case 'AGENT_KEFU': this.$refs.SystemConfigAgentKefu.onShow(this.activeName); break;
          case 'EXPRESS': this.$refs.SystemConfigExpress.onShow(this.activeName); break;
        }
      }
    },
    computed: {
      userType() {
        if(!this.$global.adminUser.type) return 0
        return this.$global.adminUser.type
      }
    }
  }
</script>

<style lang="scss">
// .v_system_config {
//   .v-system-config-show-box {
//   }
// }
</style>